import { Fragment, useEffect, useState } from 'react';

import { CallToAction } from '~/components/marketing/call-to-action';
import { isFeatureEnabled } from '~/services/flags.server';
import { isShutdownPeriod } from '~/utils/shutdown';

import type { Route } from './+types/route';
import { Banner } from './components/banner';
import { Features } from './components/features';
import { OrderCutoffAlert } from './components/order-cutoff-alert';
import { metaDescription, metaTitle } from './constants';

export function meta(): Route.MetaDescriptors {
  return [
    { title: metaTitle },
    { name: 'description', content: metaDescription },
    { name: 'robots', content: 'follow' },
  ];
}

export async function loader() {
  const isChristmasShutdown = isShutdownPeriod();

  const showAltMessage = await isFeatureEnabled('show-alt-popup-message');

  return {
    isChristmasShutdown,
    features: { ...(showAltMessage && { showAltMessage }) },
  };
}

export default function Page({ loaderData }: Route.ComponentProps) {
  const [showModal, setShowModal] = useState(false);

  const isShutdown = loaderData.isChristmasShutdown;

  useEffect(() => {
    const hasSeenModal = sessionStorage.getItem('delivery-times-modal');

    if (hasSeenModal !== 'true') {
      setTimeout(() => setShowModal(true), 1000);
    }
  }, []);

  function closeModal() {
    sessionStorage.setItem('delivery-times-modal', 'true');
    setShowModal(false);
  }

  return (
    <Fragment>
      <OrderCutoffAlert
        open={showModal}
        onClose={closeModal}
        showAltMessage={loaderData.features.showAltMessage}
      />

      <Banner className={isShutdown ? '-mt-12 sm:-mt-16' : ''} />
      <Features />
      <CallToAction />
    </Fragment>
  );
}
