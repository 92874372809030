import { Fragment } from 'react/jsx-runtime';

import { Button } from '~/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '~/components/ui/dialog';

type OrderCutoffAlertProps = {
  open?: boolean;
  onClose?: () => void;
  showAltMessage?: boolean;
};

export function OrderCutoffAlert(props: OrderCutoffAlertProps) {
  const { open, onClose, showAltMessage = false } = props;

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        onEscapeKeyDown={onClose}
        className='max-w-[calc(100vw-1.5rem)] rounded-lg sm:max-w-sm'>
        <DialogHeader className='text-left'>
          <DialogTitle className='mb-2'>
            {showAltMessage ? 'Notice' : 'Order Cut-off Time'}
          </DialogTitle>

          {showAltMessage ? (
            <DialogDescription>
              Please order extra food this week as we’ll be closed next weekend.
              Ordering will resume as normal from the 25th.
            </DialogDescription>
          ) : null}

          {!showAltMessage ? (
            <Fragment>
              <DialogDescription>
                We allow placing orders at any time but have a strict cut-off
                date.
              </DialogDescription>

              <DialogDescription>
                If you order <b>after 10:00pm ACST/ACDT each Friday</b>, you
                will <b>NOT</b> receive your order until <b>the week after</b>.
              </DialogDescription>
            </Fragment>
          ) : null}
        </DialogHeader>

        <DialogFooter>
          <DialogClose asChild>
            <Button variant='brand' type='button' className='w-full'>
              I understand
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
